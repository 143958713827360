import { React, useEffect, useState, useRef } from "react";
import {
  Button,
  Space,
  Timeline,
  Avatar,
  Card,
  Badge,
  Tag,
} from "@douyinfe/semi-ui";
import { convertUrlParamsToJson } from "../../../../utils/location_util";
import requests from "../../../../utils/request";
import { closeWindow } from "../../../../utils/dd_api";
import { useNavigate } from "react-router";

export default function YuewenLiuchengShow() {
  const route = useNavigate();
  const sw_id = convertUrlParamsToJson(window.location.search)["sw_id"];
  const [data, set_data] = useState([]);
  useEffect(() => {
    requests
      .get("/office/get_shouwen_all_liucheng", {
        params: { sw_id },
      })
      .then((res) => {
        // 后端返回数据为降序，需要处理
        if (res.data) {
          const d = res.data;
          let need_data = [];
          const len = d.length;
          const order_map = {};
          // 初始化数组
          for (let i = 0; i < len; i++) {
            if (!order_map[d[i].order_num]) {
              need_data.push([]);
              order_map[d[i].order_num] = true;
            }
          }
          // 将数据根据order_num存入数组中
          for (let i = len - 1; i >= 0; i--) {
            const liucheng = d[i];
            need_data[liucheng.order_num - 1].push(liucheng);
          }
          set_data(need_data);
        }
      });
  }, []);
  console.log(data);
  return (
    <>
      {/* <Timeline> */}
      {data.map((userlist) => {
        return <Detail user_list={userlist} />;
      })}
      {/* </Timeline> */}
      <div style={{ textAlign: "center", marginTop: "3%", marginBottom: "5%" }}>
        <Space>
          <Button onClick={() => route("/office/yuewen_app_index")}>
            返回
          </Button>
          <Button onClick={closeWindow}>关闭</Button>
        </Space>
      </div>
    </>
  );
}

function Detail({ user_list }) {
  return (
    <>
      {/* <Timeline.Item type="ongoing"> */}
      <Card>
        <h3>{user_list[0].bz}</h3>
        <Space spacing={25} wrap>
          {user_list.map((user) => (
            <>
              <div>
                <div>
                  <Badge
                    count={user.is_look ? "已处理" : "未处理"}
                    position="rightTop"
                    type={user.is_look ? "success" : "tertiary"}
                  >
                    <Avatar color="light-blue" shape="square">
                      {user.username}
                    </Avatar>
                  </Badge>
                </div>
                <div>
                {user.ldps ? <Tag> {user.ldps} </Tag> : <Tag style={{visibility: 'hidden'}}> {user.ldps} </Tag>}
                </div>
                
                
              </div>

              {/* <Input value={user.ldps} size="small" disabled/> */}
            </>
          ))}
        </Space>
      </Card>
      {/* </Timeline.Item> */}
    </>
  );
}
