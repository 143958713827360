import { React, useEffect, useState, useRef } from "react";
import {
  Tabs,
  TabPane,
  Spin,
  Input,
  Button,
  Card,
  Space,
  Tag,
  Typography,
} from "@douyinfe/semi-ui";
// import "./AppMain.modules.css";
import requests from "../../../../utils/request_noloading";
import moment from "moment";

export default function YuewenAppMain() {
  const [all_data, set_all_data] = useState([]);
  const [unread_data, set_unread_data] = useState([]);
  const [readed_data, set_readed_data] = useState([]);
  const [main_load, set_main_load] = useState(false);
  const [keyword, set_keyword] = useState("");
  const [fetch_flag, set_fetch_flag] = useState(false);

  const fetch_data = (ops, data_setter = set_all_data) => {
    set_main_load(true);
    requests
      .post("/office/search_yuewen_full_info", ops)
      .then((res) => {
        data_setter(res.data);
      })
      .finally(set_main_load(false));
  };

  const handle_search = () => {
    set_fetch_flag(!fetch_flag);
  };

  useEffect(() => {
    fetch_data({ is_look: null, keyword, is_send: true }, set_all_data);
    fetch_data({ is_look: false, keyword, is_send: true }, set_unread_data);
    fetch_data({ is_look: true, keyword, is_send: true }, set_readed_data);
  }, [fetch_flag]);

  return (
    <>
      {/* <Spin tip="数据加载中..." spinning={main_load}> */}
        <Input
          placeholder="输入关键字搜索"
          size="large"
          showClear
          onChange={(val) => set_keyword(val)}
          style={{ float: "left", width: "75%" }}
        />
        <Button
          theme="solid"
          size="large"
          onClick={handle_search}
          style={{ float: "left", width: "20%", marginLeft: "5%" }}
        >
          搜 索
        </Button>
        <br />
        <br />
        <br />
        <Tabs type="button">
          <TabPane tab={`全部(${all_data.length})`} itemKey="1">
            <YuewenInfoList datasource={all_data} />
          </TabPane>
          <TabPane tab={`未处理(${unread_data.length})`} itemKey="2">
            <YuewenInfoList datasource={unread_data} />
          </TabPane>
          <TabPane tab={`已处理(${readed_data.length})`} itemKey="3">
            <YuewenInfoList datasource={readed_data} />
          </TabPane>
        </Tabs>
      {/* </Spin> */}
    </>
  );
}

function YuewenInfoList({ datasource }) {
  return (
    <>
      {datasource.map((data, idx) => {
        return <YuwenDetail key={idx} data={data} />;
      })}
    </>
  );
}

function YuwenDetail({ data }) {
  const { Title } = Typography;
  return (
    <>
      <center>
        {data.look_time ? (
          <Tag color="grey">
            {moment(data.look_time).format("YYYY-MM-DD HH:mm")}
          </Tag>
        ) : null}
      </center>
      <Card style={{ boxShadow: "box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1)" }}>
        <div style={containerStyle}>
          {data.is_look ? (
            <>
              <Tag color="blue" style={tagStyle} size="large">
                已处理
              </Tag>
              <Tag color="blue" style={tagStyle} size="large">
                {data.bz}
              </Tag>
            </>
          ) : (
            <>
              <Tag color="orange" style={tagStyle} size="large">
                未处理
              </Tag>
              <Tag color="orange" style={tagStyle} size="large">
                {data.bz}
              </Tag>
            </>
          )}

          <Title
            style={titleStyle}
            heading={5}
            type="warning"
            link={{
              href: `/office/shouwen_yueshi?sw_id=${data.sw_id}&order=${data.order_num}`,
            }}
            underline
          >
            {data.ywbt}
          </Title>
        </div>
      </Card>
      <br />
    </>
  );
}

const containerStyle = {
  display: "flex",
  alignItems: "center", // 垂直居中对齐
  flexWrap: "wrap", // 允许内容换行
};

const tagStyle = {
  marginRight: "8px", // 标签与标题之间的间距
  borderRadius: "4px", // 圆角
  padding: "4px 8px", // 内边距
};

const titleStyle = {
  whiteSpace: "normal", // 允许文字换行
  wordBreak: "break-all", // 强制换行
  flex: 1, // 让标题占据剩余空间
};
