import { React, useState, useEffect, useRef } from "react";
import {
  Space,
  Form,
  Toast,
  Row,
  Col,
  Button,
  Popconfirm,
  Divider,
  Modal,
} from "@douyinfe/semi-ui";
import { IconUpload, IconDelete, IconClose } from "@douyinfe/semi-icons";
import requests from "../../../../../utils/request_noloading";
import { download_file } from "../../../../../utils/file";
import DingUserChose from "../../../../../component/ding_user_chose";
import { userF_bz } from "../../qianduan/shouwen_yueshi";

// 第一个添加用户栏的备注
export const userA_bz = "主要领导";

// 第二个添加用户栏的备注
export const userB_bz = "领导阅示后处理人";

// 传阅类型第一个添加用户栏的备注
export const userG_bz = "院领导";


// 领导(默认值)
const A_init = [
  {
    username: "邹家全",
    userid: "01293531133836352747",
    bz: userA_bz,
  },
];

// 领导阅示后处理人(默认值)
const B_init = [
  {
    username: "谭佳宁",
    userid: "01313100151735139419",
    bz: userB_bz,
  },
  {
    username: "张婷",
    userid: "2431205228778071",
    bz: userB_bz,
  },
  {
    username: "贺靓",
    userid: "20120161141159513",
    bz: userB_bz,
  },
];

// 院领导(默认值)
const G_init = [
  {
    username: "邹家全",
    userid: "01293531133836352747",
    bz: userG_bz,
  },
  {
    username: "宋美英",
    userid: "243030602823566830",
    bz: userG_bz,
  },
  {
    username: "蒋君",
    userid: "243030602823566830",
    bz: userG_bz,
  },
  {
    username: "周羽",
    userid: "1011360849702549",
    bz: userG_bz,
  },
  {
    username: "张漪",
    userid: "2161054159783370",
    bz: userG_bz,
  },
  {
    username: "赵战良",
    userid: "182859220635612556",
    bz: userG_bz,
  },
];

// 抄送人(默认值)
const F_init = [
  {
    username: "谭佳宁",
    userid: "01313100151735139419",
    bz: userF_bz,
  },
  {
    username: "张婷",
    userid: "2431205228778071",
    bz: userF_bz,
  },
  {
    username: "贺靓",
    userid: "20120161141159513",
    bz: userF_bz,
  },
];

// 需要新增用户的表单字段名称
let field_name = null;

export default function ShouWeiUpload() {
  const customRequest = ({ file, onSuccess }) => {
    const formData = new FormData();
    formData.append("file", file.fileInstance);
    formData.append("type", "shouwen");
    requests.post("/file/upload", formData).then((data) => {
      onSuccess(data);
    });
  };
  const [date, setdate] = useState({});
  // 当前选择框的备注
  const [cur_bz, set_cur_bz] = useState(null);

  const formApi = useRef();
  const [user_chose_v, set_user_chose_v] = useState(false);

  // 点击选择按钮调用
  const handleSelectUser = (f_name, bz) => {
    field_name = f_name;
    set_cur_bz(bz);
    set_user_chose_v(true);
  };

  // 选择好用户后的回调
  const onSelectUser = (users) => {
    users = users.map((user) => {
      user.bz = cur_bz;
      return user;
    });
    let new_users = formApi.current.getValue(field_name);
    new_users = new_users.concat(users);
    // 去重

    formApi.current.setValue(field_name, new_users);
  };

  const renderFileOperation = (fileItem) => (
    <div style={{ display: "flex", columnGap: 8, padding: "0 8px" }}>
      <Button
        onClick={(e) => fileItem.onRemove()}
        icon={<IconDelete></IconDelete>}
        type="tertiary"
        theme="borderless"
        size="small"
      ></Button>
    </div>
  );

  return (
    <>
      <DingUserChose
        visible={user_chose_v}
        setvisible={set_user_chose_v}
        onSelect={onSelectUser}
      />
      <Form
        labelPosition={"left"}
        labelAlign={"left"}
        style={{ padding: "5px", width: 800 }}
        getFormApi={(fApi) => {
          formApi.current = fApi;
        }}
        render={({ formState, formApi, values }) => (
          <div>
            <Row>
              <center>
                <h2>收文填写</h2>
              </center>
              <Col span={24}>
                <Form.Select
                  label="收文类型"
                  field="sw_type"
                  style={{ width: "250px" }}
                >
                  <Form.Select.Option value="行政发文">
                    行政发文
                  </Form.Select.Option>
                  <Form.Select.Option value="中共党委发文">
                    中共党委发文
                  </Form.Select.Option>
                  {/* <Form.Select.Option value="会议及培训">
                    会议及培训
                  </Form.Select.Option> */}
                  <Form.Select.Option value="传阅">传阅</Form.Select.Option>
                </Form.Select>

                {["行政发文", "中共党委发文"].includes(
                  formState.values.sw_type
                ) ? (
                  <Form.Input field="fwdw" label="来文单位" placeholder="" />
                ) : null}

                {["行政发文", "中共党委发文"].includes(
                  formState.values.sw_type
                ) ? (
                  <Form.DatePicker
                    field="fwsj"
                    label="发文时间"
                    initValue={new Date()}
                    style={{ width: "250px" }}
                  />
                ) : null}

                {["行政发文", "中共党委发文"].includes(
                  formState.values.sw_type
                ) ? (
                  <Form.Input field="ywbh" label="原文编号" placeholder="" />
                ) : null}

                {["行政发文", "中共党委发文", "传阅"].includes(
                  formState.values.sw_type
                ) ? (
                  <Form.Input field="ywbt" label="原文标题" placeholder="" />
                ) : null}

                {["行政发文", "中共党委发文"].includes(
                  formState.values.sw_type
                ) ? (
                  <Form.DatePicker
                    field="swsj"
                    label="收文时间"
                    initValue={new Date()}
                    style={{ width: "250px" }}
                  />
                ) : null}

                {["行政发文", "中共党委发文"].includes(
                  formState.values.sw_type
                ) ? (
                  <Form.Input field="swsxh" label="收文顺序号" placeholder="" />
                ) : null}

                {["行政发文", "中共党委发文"].includes(
                  formState.values.sw_type
                ) ? (
                  <Form.AutoComplete
                    field="swtj"
                    label="收文途径"
                    data={["钉钉", "微信", "OA"]}
                    placeholder="输入或选择"
                    style={{ width: 300 }}
                  ></Form.AutoComplete>
                ) : null}

                {["行政发文", "中共党委发文"].includes(
                  formState.values.sw_type
                ) ? (
                  <Form.Select
                    label="批阅范围"
                    field="pyfw"
                    initValue="呈请邹书记阅示"
                    style={{ width: "250px" }}
                  >
                    <Form.Select.Option value="呈请邹书记阅示">
                      呈请邹书记阅示
                    </Form.Select.Option>
                    <Form.Select.Option value="呈请院长阅示">
                      呈请院长阅示
                    </Form.Select.Option>
                  </Form.Select>
                ) : null}

                {["行政发文", "中共党委发文"].includes(
                  formState.values.sw_type
                ) ? (
                  <Form.Input
                    field="szbm"
                    label="所在部门"
                    initValue={"办公室"}
                  />
                ) : null}

                {["行政发文", "中共党委发文"].includes(
                  formState.values.sw_type
                ) ? (
                  <Form.Input field="type" label="类别" />
                ) : null}

                {["行政发文", "中共党委发文"].includes(
                  formState.values.sw_type
                ) ? (
                  <Form.Input field="ncdwx" label="拟存档文限" />
                ) : null}

                {/* <Form.Input field="clyj" label="处理意见" /> */}

                {["行政发文", "中共党委发文"].includes(
                  formState.values.sw_type
                ) ? (
                  <Form.DatePicker
                    field="sx"
                    label="时限"
                    initValue={"2099-12-31"}
                    style={{ width: "250px" }}
                  />
                ) : null}

                {["行政发文", "中共党委发文"].includes(
                  formState.values.sw_type
                ) ? (
                  <Form.TextArea
                    field="bz"
                    label="备注"
                    placeholder="文字内容"
                    size="large"
                  ></Form.TextArea>
                ) : null}

                <Form.Upload
                  customRequest={customRequest}
                  field="fj"
                  label="附件"
                  itemStyle={{ width: 300 }}
                  renderFileOperation={renderFileOperation}
                >
                  <Button icon={<IconUpload />} theme="light">
                    上传附件
                  </Button>
                </Form.Upload>
              </Col>
              <Divider margin="12px" />
            </Row>

            {["行政发文", "中共党委发文"].includes(date.sw_type) ? (
              <Row>
                <Col span={20}>
                  <Form.TagInput
                    field="User_A"
                    allowDuplicates={false}
                    label={userA_bz}
                    initValue={A_init}
                    style={{ width: "90%" }}
                    renderTagItem={(value, index, onClose) =>
                      renderTagItem(value, index, onClose)
                    }
                  />
                </Col>
                <Col offset={1} span={3}>
                  <Button
                    type="primary"
                    style={{ marginTop: 12 }}
                    onClick={() => {
                      handleSelectUser("User_A", userA_bz);
                    }}
                  >
                    新增
                  </Button>
                </Col>
              </Row>
            ) : null}

            {["行政发文", "中共党委发文"].includes(date.sw_type) ? (
              <Row>
                <Col span={20}>
                  <Form.TagInput
                    field="User_B"
                    label={userB_bz}
                    initValue={B_init}
                    style={{ width: "90%" }}
                    renderTagItem={(value, index, onClose) =>
                      renderTagItem(value, index, onClose)
                    }
                  />
                </Col>
                <Col offset={1} span={3}>
                  <Button
                    type="primary"
                    style={{ marginTop: 12 }}
                    onClick={() => {
                      handleSelectUser("User_B", userB_bz);
                    }}
                  >
                    新增
                  </Button>
                </Col>
              </Row>
            ) : null}

            {["传阅"].includes(date.sw_type) ? (
              <Row>
                <Col span={20}>
                  <Form.TagInput
                    field="User_A"
                    allowDuplicates={false}
                    label={userG_bz}
                    initValue={G_init}
                    style={{ width: "90%" }}
                    renderTagItem={(value, index, onClose) =>
                      renderTagItem(value, index, onClose)
                    }
                  />
                </Col>
                <Col offset={1} span={3}>
                  <Button
                    type="primary"
                    style={{ marginTop: 12 }}
                    onClick={() => {
                      handleSelectUser("User_A", userG_bz);
                    }}
                  >
                    新增
                  </Button>
                </Col>
              </Row>
            ) : null}

            {["传阅"].includes(date.sw_type) ? (
              <Row>
                <Col span={20}>
                  <Form.TagInput
                    field="User_B"
                    label={userF_bz}
                    initValue={F_init}
                    style={{ width: "90%" }}
                    renderTagItem={(value, index, onClose) =>
                      renderTagItem(value, index, onClose)
                    }
                  />
                </Col>
                <Col offset={1} span={3}>
                  <Button
                    type="primary"
                    style={{ marginTop: 12 }}
                    onClick={() => {
                      handleSelectUser("User_B", userF_bz);
                    }}
                  >
                    新增
                  </Button>
                </Col>
              </Row>
            ) : null}

            <Row>
              <Divider margin="12px" />
              <Space>
                <Popconfirm
                  title="是否确定提交台账"
                  position="bottom"
                  onConfirm={() => {
                    let fj = [];
                    if (date["fj"] != null) {
                      fj = date["fj"].map((val) => {
                        return { name: val.name, path: val.response.data.path };
                      });
                    }
                    values["fj"] = fj;
                    values["users"] = [values["User_A"], values["User_B"]];
                    requests
                      .post("/office/upload_shouwen_msg", values)
                      .then((da) => {
                        Modal.success({
                          title: "操作成功",
                          content: "提交成功！",
                          hasCancel: false,
                          centered: true,
                          onOk: () => window.location.reload(),
                        });
                      });
                  }}
                >
                  <Button type="primary" style={{ marginTop: 12 }}>
                    提交
                  </Button>
                </Popconfirm>
              </Space>
            </Row>
          </div>
        )}
        onValueChange={(values) => {
          setdate(values);
          // console.log(values);
        }}
      ></Form>
    </>
  );
}

export function renderTagItem(value, index, onClose) {
  return (
    <div
      key={index}
      style={{
        display: "flex",
        alignItems: "center",
        fontSize: 14,
        marginRight: 10,
        backgroundColor: "var(--semi-color-bg-4)",
        border: "1px solid rgba(var(--semi-grey-2), .7)",
        color: "var(--semi-color-text-0)",
      }}
    >
      <span style={{ marginLeft: 8 }}>{`${value.username}`}</span>
      <IconClose
        size="small"
        onClick={onClose}
        style={{ color: "#979D9E", marginLeft: 5 }}
      />
    </div>
  );
}
