import { React, useEffect, useState, useRef } from "react";
import {
  Button,
  Space,
  Steps,
  Avatar,
  Card,
  Badge,
  Tag,
} from "@douyinfe/semi-ui";
import { IconHourglass } from '@douyinfe/semi-icons';
import { convertUrlParamsToJson } from "../../../../utils/location_util";
import requests from "../../../../utils/request";
import { closeWindow } from "../../../../utils/dd_api";
import { useNavigate } from "react-router";

const fake_data = [
  {username: "44", is_look: false, order_num: 4, bz: '第四顺序'},
  {username: "33", is_look: false, order_num: 3, bz: '第三顺序'},
  {username: "22", is_look: true, order_num: 2, bz: '第二顺序'},
  {username: "222", is_look: true, order_num: 2, bz: '第二顺序'},
  {username: "222", is_look: true, order_num: 2, bz: '第二顺序'},
  {username: "222", is_look: true, order_num: 2, bz: '第二顺序'},
  {username: "222", is_look: true, order_num: 2, bz: '第二顺序'},
  {username: "11", is_look: true, order_num: 1, bz: '第一顺序', ldps: "duawhudhnaiuwd"},
]

export default function YuewenLiuchengShow({sw_id}) {
  const route = useNavigate();
  // const sw_id = convertUrlParamsToJson(window.location.search)["sw_id"];
  const [data, set_data] = useState([]);
  const [cur_finish_order, set_cur_finish_order] = useState(0);
  useEffect(() => {
    requests
      .get("/office/get_shouwen_all_liucheng", {
        params: { sw_id },
      })
      .then((res) => {
        // 后端返回数据为降序，需要处理
        if (res.data) {
          const d = res.data;
          // const d = fake_data;
          let need_data = [];
          const len = d.length;
          const order_map = {};
          // 初始化数组
          for (let i = 0; i < len; i++) {
            if (!order_map[d[i].order_num]) {
              need_data.push([]);
              order_map[d[i].order_num] = true;
            }
          }
          // 将数据根据order_num存入数组中
          let cur_order = len
          let flag = true
          for (let i = len - 1; i >= 0; i--) {
            const liucheng = d[i];
            if (flag && !liucheng.is_look) {
              cur_order = liucheng.order_num - 1
              flag = false
            }
            need_data[liucheng.order_num - 1].push(liucheng);
          }
          set_cur_finish_order(cur_order)
          set_data(need_data);
        }
      });
  }, []);
  return (
    <div>
      <Steps
        direction="vertical"
        type="basic"
        current={cur_finish_order}
        size="small"
        onChange={(i) => console.log(i)}
      >
        {data.map((userlist, idx) => {
          return (
            <Steps.Step
              key={idx}
              title={userlist[0].bz}
              description={<Detail user_list={userlist} />}
            />
          );
        })}
      </Steps>
    </div>
  );
}

function Detail({ user_list }) {
  return (
    <div style={{ marginTop: 20 }}>
      <Space spacing={25} wrap>
        {user_list.map((user, idx) => (
          <div key={idx}>
            <div>
              <div>
                <Badge
                  count={user.is_look ? "✔" : <IconHourglass />}
                  position="rightTop"
                  type={user.is_look ? "success" : "tertiary"}
                >
                  <Avatar color="light-blue" shape="square" size="default" gap={0} >
                    {user.username}
                  </Avatar>
                </Badge>
              </div>
              <div>
                {user.ldps ? (
                  <Tag> {user.ldps} </Tag>
                ) : (
                  <Tag style={{ visibility: "hidden" }}> {user.ldps} </Tag>
                )}
              </div>
            </div>
          </div>
        ))}
      </Space>
    </div>
  );
}
