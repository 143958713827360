import {
  Layout,
  Button,
  Table,
  Toast,
  Tag,
  Space,
  Row,
  Col,
  Form,
} from "@douyinfe/semi-ui";
import { IconInstagram } from "@douyinfe/semi-icons";
import React, { useState, useEffect } from "react";
import request from "../../../../../utils/request_noloading";
import "../../../../../css/a.css";
import DelButton from "../../../../../component/del_button";
import ShowFileList from "../show_file_list";
import ShouWenTaiZhangModify from "../shouwen_taizhang_modify";
import ShouwenLiuChengShow from "../shouwen_liucheng_show";

export default function ShouwenTaiZhang() {
  //分页条件
  const [size, setSize] = useState(10);
  const [curPage, setcurPage] = useState(1);
  const [total, setTotal] = useState(0);
  //当前页数据
  const [das, setdas] = useState([{}]);
  //刷新
  const [shouwen_refresh, setshouwen_refresh] = useState(false);
  //修改表单数据
  const [modifyflag, setmodifyflag] = useState(false);
  const [modifyvalue, setmodifyvalue] = useState({});
  //表单数据
  const [formdata, formdataSetdas] = useState({});
  // 文件展示
  const [fileflag, setfileflag] = useState(false);
  const [item_id, setitem_id] = useState(0);
  // 收文流程展示
  const [liucheng_v, set_liucheng_v] = useState(false);
  //页面布局
  const { Header, Content } = Layout;
  //列名
  const columns = [
    {
      dataIndex: "swsxh",
      title: "收文顺序号",
      width: 110,
      sorter: (a, b) => (a.swsxh > b.swsxh ? 1 : -1),
    },
    { dataIndex: "fwdw", title: "来文单位", ellipsis: true },
    { dataIndex: "ywbt", title: "原文标题" },
    { dataIndex: "ywbh", title: "原文编号" },
    {
      dataIndex: "fwsj",
      title: "发文时间",
      width: 110,
      sorter: (a, b) => (a.fwsj > b.fwsj ? 1 : -1),
    },
    {
      dataIndex: "swsj",
      title: "收文时间",
      width: 110,
      sorter: (a, b) => (a.swsj > b.swsj ? 1 : -1),
    },
    {
      dataIndex: "sx",
      title: "时限",
      width: 160,
      render: (text, record, index) => {
        let m = Math.trunc(
          (new Date(text).getTime() - new Date().getTime()) /
            1000 /
            60 /
            60 /
            24
        );
        if (m < 0) {
          return (
            <>
              <Row span={22}>
                <Col span={12}>{text}</Col>
                <Col span={8}>
                  <Space>
                    <Tag size="small" color="red">
                      已到期
                    </Tag>
                  </Space>
                </Col>
              </Row>
            </>
          );
        } else if (m >= 0 && m <= 1) {
          return (
            <>
              <Row span={22}>
                <Col span={12}>{text}</Col>
                <Col span={8}>
                  <Space>
                    <Tag size="small" color="amber">
                      即将到期
                    </Tag>
                  </Space>
                </Col>
              </Row>
            </>
          );
        } else {
          return (
            <>
              <Row span={22}>
                <Col span={12}>{text}</Col>
                <Col span={8}>
                  <Space>
                    <Tag size="small" color="green">
                      还剩{m <= 99 ? m : "很多"}天
                    </Tag>
                  </Space>
                </Col>
              </Row>
            </>
          );
        }
      },
    },
    { dataIndex: "ldps", title: "领导批示", ellipsis: true },
    {
      title: "操作",
      width: 200,
      dataIndex: "operate",
      render: (text, record) => (
        <>
          <Button
            onClick={() => {
              setitem_id(record.id);
              setfileflag(true);
            }}
            size="small"
          >
            附件管理
          </Button>
          &nbsp;
          <Button
            onClick={() => {
              setitem_id(record.id);
              set_liucheng_v(true);
            }}
            size="small"
          >
            查看阅示进度
          </Button>
          &nbsp;
          <Button
            onClick={() => {
              setmodifyflag(true);
              setmodifyvalue(record);
              console.log(record);
            }}
            size="small"
          >
            修改
          </Button>
          &nbsp;
          <DelButton
            onOk={() => {
              request
                .get("/office/del_shouwen_data?id=" + record.id)
                .then((res) => {
                  setshouwen_refresh(true);
                });
            }}
            size="small"
          ></DelButton>
          &nbsp;
        </>
      ),
    },
  ];
  //加载数据
  useEffect(() => {
    getdatas();
    setshouwen_refresh(false);
  }, [curPage, size, shouwen_refresh]);
  //页码变化
  function onChange(currentPage, pageSize) {
    setcurPage(currentPage); //当前页数
    setSize(pageSize);
    // getdatas()
  }
  //获取数据
  const getdatas = () => {
    request
      .post("/office/get_shouwen_data", {
        currentPage: curPage, //页码
        pageSize: size, //尺寸
        keywords: formdata.keywords, //关键字
        nf: formdata.nf, //年份
        dqsj: formdata.dqsj, //到期时间
      })
      .then((res) => {
        setdas(res.data.result);
        setTotal(res.data.total);
      });
  };
  return (
    <>
      <Layout className="components-layout-demo">
        <Header>
          <Form
            onSubmit={(values) =>
              Toast.info({ content: JSON.stringify(values) })
            }
            render={({ formState, formApi, values }) => (
              <div>
                <Row span={24}>
                  <Col span={6}>
                    <Form.Input
                      field="keywords"
                      label="关键字"
                      placeholder="输入关键字搜索"
                      prefix={<IconInstagram />}
                      style={{ width: 176 }}
                    />
                  </Col>
                  <Col span={4} offset={1}>
                    <Form.Select
                      field="nf"
                      label="年份"
                      style={{ width: "320px" }}
                      defaultValue={"ulikecam"}
                      showClear={true}
                    >
                      <Form.Select.Option value="">请选择</Form.Select.Option>
                      <Form.Select.Option value="2024">2024</Form.Select.Option>
                      <Form.Select.Option value="2023">2023</Form.Select.Option>
                      <Form.Select.Option value="2022">2022</Form.Select.Option>
                    </Form.Select>
                  </Col>
                  <Col span={10} offset={3}>
                    <Form.Select
                      field="dqsj"
                      label="到期时间"
                      style={{ width: "320px" }}
                      defaultValue={"ulikecam"}
                      showClear={true}
                    >
                      <Form.Select.Option value="">请选择</Form.Select.Option>
                      <Form.Select.Option value="已到期">
                        已到期
                      </Form.Select.Option>
                      <Form.Select.Option value="即将到期">
                        即将到期{"（<=1天）"}
                      </Form.Select.Option>
                    </Form.Select>
                  </Col>
                </Row>
                <Row>
                  <br></br>
                  <Col span={1}>
                    <Button
                      onClick={() => {
                        setcurPage(1);
                        getdatas();
                      }}
                    >
                      提交
                    </Button>
                  </Col>
                  <Col span={1} offset={1}>
                    <Button
                      htmlType="reset"
                      onClick={() => {
                        formdataSetdas({});
                      }}
                    >
                      重置条件
                    </Button>
                  </Col>
                </Row>
              </div>
            )}
            onValueChange={(values) => {
              formdataSetdas(values);
            }}
          ></Form>
        </Header>
        <Content>
          <ShowFileList
            visible={fileflag}
            setvisible={setfileflag}
            item_id={item_id}
            type={"shouwen"}
          ></ShowFileList>
          <ShouWenTaiZhangModify
            visible={modifyflag}
            setvisible={setmodifyflag}
            value={modifyvalue}
            setrefresh={setshouwen_refresh}
          ></ShouWenTaiZhangModify>
          <br></br>
          <Table
            columns={columns}
            dataSource={das}
            pagination={{
              total: total,
              showSizeChanger: true,
              defaultCurrentPage: 1,
              currentPage: curPage,
              pageSize: size,
              pageSizeOpts: [10, 20, 50, 200],
              onChange: onChange,
            }}
            bordered={true}
            size={"middle"}
            sticky={true}
          />
          <ShouwenLiuChengShow
            visible={liucheng_v}
            setvisible={set_liucheng_v}
            sw_id={item_id}
          />
        </Content>
      </Layout>
    </>
  );
}
